define('ember-power-calendar/services/power-calendar', ['exports', 'ember-metal/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    date: null,

    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      this._calendars = {};
    },


    // Methods
    getDate: function getDate() {
      return this.get('date') || new Date();
    },
    registerCalendar: function registerCalendar(calendar) {
      this._calendars[(0, _utils.guidFor)(calendar)] = calendar;
    },
    unregisterCalendar: function unregisterCalendar(calendar) {
      delete this._calendars[(0, _utils.guidFor)(calendar)];
    }
  });
});