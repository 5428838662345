define('ember-light-table/components/lt-foot', ['exports', 'ember-light-table/templates/components/lt-foot', 'ember-light-table/mixins/table-header'], function (exports, _ltFoot, _tableHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var trySet = Ember.trySet;
  exports.default = Component.extend(_tableHeader.default, {
    layout: _ltFoot.default,
    classNames: ['lt-foot-wrap'],
    table: null,
    sharedOptions: null,

    init: function init() {
      this._super.apply(this, arguments);

      trySet(this, 'sharedOptions.fixedFooter', get(this, 'fixed'));
    }
  });
});