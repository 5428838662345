define('ember-light-table/classes/Row', ['exports', 'ember-light-table/utils/fix-proto'], function (exports, _fixProto) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var ObjectProxy = Ember.ObjectProxy;
  var computed = Ember.computed;
  var guidFor = Ember.guidFor;

  var Row = function (_ObjectProxy$extend) {
    _inherits(Row, _ObjectProxy$extend);

    /**
     * @class Row
     * @constructor
     * @param {Object} content
     * @param {Object} options
     */
    function Row(content) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      _classCallCheck(this, Row);

      var _this = _possibleConstructorReturn(this, (Row.__proto__ || Object.getPrototypeOf(Row)).call(this));

      if (content instanceof Row) {
        var _ret;

        return _ret = content, _possibleConstructorReturn(_this, _ret);
      }

      _this.setProperties(options);
      _this.set('content', content);
      return _this;
    }

    return Row;
  }(ObjectProxy.extend({
    /**
     * Whether the row is hidden.
     *
     * CSS Classes:
     *  - `is-hidden`
     *
     * @property hidden
     * @type {Boolean}
     * @default false
     */
    hidden: false,

    /**
     * Whether the row is expanded.
     *
     * CSS Classes:
     *  - `is-expanded`
     *
     * @property expanded
     * @type {Boolean}
     * @default false
     */
    expanded: false,

    /**
     * Whether the row is selected.
     *
     * CSS Classes:
     *  - `is-selected`
     *
     * @property selected
     * @type {Boolean}
     * @default false
     */
    selected: false,

    /**
     * Class names to be applied to this row
     *
     * @property classNames
     * @type {String | Array}
     */
    classNames: null,

    /**
     * Data content for this row. Since this class extends Ember.ObjectProxy,
     * all properties are forwarded to the content. This means that instead of
     * `row.content.foo` you can just do `row.foo`. Please note that methods are
     * not forwarded. You will not be able to do `row.save()`, instead, you would have
     * to do `row.content.save()`.
     *
     * @property content
     * @type {Object}
     */
    content: null,

    /**
     * Rows's unique ID.
     *
     * Note: named `rowId` in order to not shadow the `content.id` property.
     *
     * @property rowId
     * @type {String}
     * @readOnly
     */
    rowId: computed(function () {
      return guidFor(this);
    }).readOnly()
  }));

  exports.default = Row;


  // https://github.com/offirgolan/ember-light-table/issues/436#issuecomment-310138868
  (0, _fixProto.default)(Row);
});