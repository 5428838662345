define('ember-light-table/components/lt-spanned-row', ['exports', 'ember-light-table/templates/components/lt-spanned-row'], function (exports, _ltSpannedRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _ltSpannedRow.default,
    colspan: 1,
    tagName: '',
    visible: true
  });
});