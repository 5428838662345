define('@html-next/vertical-collection/components/vertical-collection/component', ['exports', '@html-next/vertical-collection/components/vertical-collection/template', 'ember-raf-scheduler', '@html-next/vertical-collection/-private'], function (exports, _template, _emberRafScheduler, _private) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var empty = Ember.computed.empty;
  var readOnly = Ember.computed.readOnly;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var run = Ember.run;


  var VerticalCollection = Component.extend({
    layout: _template.default,

    tagName: '',

    /**
     * Property name used for storing references to each item in items. Accessing this attribute for each item
     * should yield a unique result for every item in the list.
     *
     * @property key
     * @type String
     * @default '@identity'
     */
    key: '@identity',

    // –––––––––––––– Required Settings

    /**
     * Estimated height of an item to be rendered. Use best guess as this will be used to determine how many items
     * are displayed virtually, before and after the vertical-collection viewport.
     *
     * @property estimateHeight
     * @type Number
     * @required
     */
    estimateHeight: null,

    /**
     * List of objects to svelte-render.
     * Can be called like `{{#vertical-collection <items-array>}}`, since it's the first positional parameter of this component.
     *
     * @property items
     * @type Array
     * @required
     */
    items: null,

    // –––––––––––––– Optional Settings
    /**
     * Indicates if the occluded items' heights will change or not.
     * If true, the vertical-collection will assume that items' heights are always equal to estimateHeight;
     * this is more performant, but less flexible.
     *
     * @property staticHeight
     * @type Boolean
     */
    staticHeight: false,

    /**
     * Indicates whether or not list items in the Radar should be reused on update of virtual components (e.g. scroll).
     * This yields performance benefits because it is not necessary to repopulate the component pool of the radar.
     * Set to false when recycling a component instance has undesirable ramifications including:
     *  - When using `unbound` in a component or sub-component
     *  - When using init for instance state that differs between instances of a component or sub-component
     *      (can move to didInitAttrs to fix this)
     *  - When templates for individual items vary widely or are based on conditionals that are likely to change
     *      (i.e. would defeat any benefits of DOM recycling anyway)
     *
     * @property shouldRecycle
     * @type Boolean
     */
    shouldRecycle: true,

    /*
     * A selector string that will select the element from
     * which to calculate the viewable height and needed offsets.
     *
     * This element will also have the `scroll` event handler added to it.
     *
     * Usually this element will be the component's immediate parent element,
     * if so, you can leave this null.
     *
     * Set this to "body" to scroll the entire web page.
     */
    containerSelector: '*',

    // –––––––––––––– Performance Tuning
    /**
     * The amount of extra items to keep visible on either side of the viewport -- must be greater than 0.
     * Increasing this value is useful when doing infinite scrolling and loading data from a remote service,
     * with the desire to allow records to show as the user scrolls and the backend API takes time to respond.
     *
     * @property bufferSize
     * @type Number
     * @default 1
     */
    bufferSize: 1,

    // –––––––––––––– Initial Scroll State
    /**
     * If set, upon initialization the scroll
     * position will be set such that the item
     * with the provided id is at the top left
     * on screen.
     *
     * If the item cannot be found, scrollTop
     * is set to 0.
     * @property idForFirstItem
     */
    idForFirstItem: null,

    /**
     * If set, if scrollPosition is empty
     * at initialization, the component will
     * render starting at the bottom.
     * @property renderFromLast
     * @type Boolean
     * @default false
     */
    renderFromLast: false,

    /**
     * If set to true, the collection will render all of the items passed into the component.
     * This counteracts the performance benefits of using vertical collection, but has several potential applications,
     * including but not limited to:
     *
     * - It allows for improved accessibility since all elements are rendered and can be picked up by a screen reader.
     * - Can be applied in SEO solutions (i.e. fastboot) where rendering every item is desirable.
     * - Can be used to respond to the keyboard input for Find (i.e. ctrl+F/cmd+F) to show all elements, which then
     *    allows the list items to be searchable
     *
     * @property renderAll
     * @type Boolean
     * @default false
     */
    renderAll: false,

    isEmpty: empty('items'),
    shouldYieldToInverse: readOnly('isEmpty'),

    virtualComponents: computed('items.[]', 'renderAll', 'estimateHeight', 'bufferSize', function () {
      var _radar = this._radar;


      var items = this.get('items');

      _radar.items = items === null || items === undefined ? [] : items;
      _radar.estimateHeight = this.get('estimateHeight');
      _radar.renderAll = this.get('renderAll');
      _radar.bufferSize = this.get('bufferSize');

      _radar.scheduleUpdate(true);

      return _radar.virtualComponents;
    }),

    schedule: function schedule(queueName, job) {
      return _emberRafScheduler.scheduler.schedule(queueName, job, this.token);
    },
    _scheduleSendAction: function _scheduleSendAction(action, index) {
      var _this = this;

      this._scheduledActions.push([action, index]);

      if (this._nextSendActions === null) {
        this._nextSendActions = setTimeout(function () {
          _this._nextSendActions = null;

          run(function () {
            var items = _this.get('items');
            var keyPath = _this.get('key');

            _this._scheduledActions.forEach(function (_ref) {
              var action = _ref[0],
                  index = _ref[1];

              var item = (0, _private.objectAt)(items, index);
              var key = (0, _private.keyForItem)(item, keyPath, index);

              _this.sendAction(action, item, index, key);
            });
            _this._scheduledActions.length = 0;
          });
        });
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this.schedule('sync', function () {
        _this2._radar.start();
      });
    },
    willDestroy: function willDestroy() {
      this.token.cancel();
      this._radar.destroy();
      clearTimeout(this._nextSendActions);
    },
    init: function init() {
      var _this3 = this;

      this._super();

      this.token = new _emberRafScheduler.Token();
      var RadarClass = this.staticHeight ? _private.StaticRadar : _private.DynamicRadar;

      var items = this.get('items') || [];

      var bufferSize = this.get('bufferSize');
      var containerSelector = this.get('containerSelector');
      var estimateHeight = this.get('estimateHeight');
      var initialRenderCount = this.get('initialRenderCount');
      var renderAll = this.get('renderAll');
      var renderFromLast = this.get('renderFromLast');
      var shouldRecycle = this.get('shouldRecycle');

      var idForFirstItem = this.get('idForFirstItem');
      var key = this.get('key');

      var startingIndex = calculateStartingIndex(items, idForFirstItem, key, renderFromLast);

      this._radar = new RadarClass(this.token, {
        bufferSize: bufferSize,
        containerSelector: containerSelector,
        estimateHeight: estimateHeight,
        initialRenderCount: initialRenderCount,
        items: items,
        key: key,
        renderAll: renderAll,
        renderFromLast: renderFromLast,
        shouldRecycle: shouldRecycle,
        startingIndex: startingIndex
      });

      this._prevItemsLength = 0;
      this._prevFirstKey = null;
      this._prevLastKey = null;

      this._hasAction = null;
      this._scheduledActions = [];
      this._nextSendActions = null;

      var a = !!this.lastReached;
      var b = !!this.firstReached;
      var c = !!this.lastVisibleChanged;
      var d = !!this.firstVisibleChanged;
      var any = a || b || c || d;

      if (any) {
        this._hasAction = {
          lastReached: a,
          firstReached: b,
          lastVisibleChanged: c,
          firstVisibleChanged: d
        };

        this._radar.sendAction = function (action, index) {
          if (_this3._hasAction[action]) {
            _this3._scheduleSendAction(action, index);
          }
        };
      }
    }
  });

  VerticalCollection.reopenClass({
    positionalParams: ['items']
  });

  if (!true) {
    VerticalCollection.reopen({
      shouldYieldToInverse: false
    });
  }

  function calculateStartingIndex(items, idForFirstItem, key, renderFromLast) {
    var totalItems = get(items, 'length');

    var startingIndex = 0;

    if (idForFirstItem !== undefined && idForFirstItem !== null) {
      for (var i = 0; i < totalItems; i++) {
        if ((0, _private.keyForItem)((0, _private.objectAt)(items, i), key, i) === idForFirstItem) {
          startingIndex = i;
          break;
        }
      }
    } else if (renderFromLast === true) {
      // If no id was set and `renderFromLast` is true, start from the bottom
      startingIndex = totalItems - 1;
    }

    return startingIndex;
  }

  exports.default = VerticalCollection;
});