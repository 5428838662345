define('ember-bootstrap/components/base/bs-tooltip/element', ['exports', 'ember-bootstrap/templates/components/bs-tooltip/element', 'ember-bootstrap/components/base/bs-contextual-help/element'], function (exports, _element, _element2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _element2.default.extend({
    layout: _element.default,

    /**
     * @property arrowClass
     * @private
     */
    arrowClass: 'tooltip-arrow'
  });
});