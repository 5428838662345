define('ember-light-table/components/lt-scrollable', ['exports', 'ember-light-table/templates/components/lt-scrollable'], function (exports, _ltScrollable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _ltScrollable.default,
    tagName: '',
    vertical: true,
    horizontal: false
  });
});